.adm-picker {
  --header-button-font-size: 15px;
  --title-font-size: 15px;
  --item-font-size: 16px;
  --item-height: 34px;
  width: 100%;
  height: 300px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  ---item-font-size: var(--item-font-size);
  ---item-height: var(--item-height);
}
.adm-picker .adm-picker-view.adm-picker-view {
  --item-font-size: var(---item-font-size);
  --item-height: var(---item-height);
}
.adm-picker-header {
  flex: none;
  border-bottom: solid 1px var(--adm-border-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 4px;
}
.adm-picker-header-button {
  font-size: var(--header-button-font-size);
  display: inline-block;
  padding: 8px 8px;
}
.adm-picker-header-title {
  padding: 4px 4px;
  font-size: var(--title-font-size);
  color: var(--adm-color-text);
  text-align: center;
  flex: 1;
}
.adm-picker-body {
  flex: auto;
  height: 100%;
  width: 100%;
}
.adm-picker-body > .adm-picker-view {
  --height: 100%;
}
.adm-picker-popup .adm-popup-body {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
